const menu = document.querySelector('#js-menu');
const menuOpen = document.querySelector('#js-menu-open');
const menuList = document.querySelector('#js-menu-list');

function openMenu() {
  menu.classList.remove('is-closed');
  menuOpen.classList.add('is-active');
  window.setTimeout(function() {
    menuList.classList.add('is-active');
  }, 0);
}

export function closeMenu() {
  if (menuList.classList.contains('is-active')) {
    menuList.classList.remove('is-active');
    menuOpen.classList.remove('is-active');
    window.setTimeout(function() {
      menu.classList.add('is-closed');
    }, 400);
  }
}

function toggleMenu() {
  if (menu.classList.contains('is-closed')) {
    openMenu();
  } else {
    closeMenu();
  }
}

export function init() {
  menuOpen.addEventListener('click', toggleMenu, false);
}
