import axios from 'axios';
import slider from './slider.js';

const modal = document.querySelector('#js-modal');
const modalInner = document.querySelector('#js-modal-inner');
const modalLoader = document.querySelector('#js-modal-loader');
const modalOverlay = document.querySelector('#js-modal-overlay');

function handleClick(event) {
  const link = event.target.closest('.js-modal-open');
  const button = event.target.closest('#js-modal-close');
  const modalBox = event.target.closest('#js-modal-box');

  if (link) {
    event.preventDefault();
    openModal(link.href, false);
    return;
  }

  if (button) {
    closeModal(false);
    return;
  }

  // If modal is open, and user clicks outside the box, close it.
  if (document.body.classList.contains('modal-is-open') && !modalBox) {
    event.preventDefault();
    closeModal(false);
    return;
  }
}

export function openModal(url, isPopState) {
  document.body.classList.add('modal-is-open');
  modalOverlay.classList.remove('is-closed');
  modal.classList.remove('is-closed');
  modalLoader.classList.remove('is-hidden');
  loadModal(url, isPopState);
}

export function closeModal(isPopState) {
  document.body.classList.remove('modal-is-open');
  modalOverlay.classList.add('is-closed');
  modal.classList.add('is-closed');
  modalInner.innerHTML = "";

  if (isPopState === false) {
    let url = location.href.split('/');
    url.pop();
    url = url.join('/');
    history.pushState({ url, modal: false }, null, url);
  }
}

function loadModal(url, isPopState) {
  axios.get(url)
    .then(response => {
      processModalAjax(response, url, isPopState);
    })
    .catch(error => console.log(error));
}

function processModalAjax(response, url, isPopState) {
  const fragment = document.createRange().createContextualFragment(response.data);
  const content = fragment.querySelector('#js-modal-content');
  document.title = fragment.querySelector('title').innerHTML;
  modalInner.appendChild(content);
  modalLoader.classList.add('is-hidden');
  if (isPopState === false) {
    history.pushState({ url, modal: true }, null, url);
  }
  slider();
}

export function init() {
  document.addEventListener('click', handleClick, false);
}
