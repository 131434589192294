import axios from 'axios';
import animateScrollTo from 'animated-scroll-to';
import { closeMenu } from './menu';
import { openModal, closeModal } from './modal';

const container = document.querySelector('#js-ajax-container');

function handleClick(event) {
  const link = event.target.closest('.js-ajax-link');
  if (link) {
    event.preventDefault();
    loadAjaxContent(link.href, false);
    return;
  }
}

function loadAjaxContent(url, isPopState) {
  document.body.classList.add('is-loading');
  closeMenu();
  updateLayout(url);
  axios.get(url)
    .then(response => {
      processAjax(response, url, isPopState);
    })
    .catch(error => console.log(error));
}

function updateLayout(url) {
  const path = url.split('/');
  if (path[3] != '') {
    document.body.classList.add('compact-layout');
  } else {
    document.body.classList.remove('compact-layout');
  }
}

function processAjax(response, url, isPopState) {
  const fragment = document.createRange().createContextualFragment(response.data);
  const content = fragment.querySelector('.js-ajax-content');
  document.title = fragment.querySelector('title').innerHTML;

  animateScrollTo(0).then(() => {
    container.innerHTML = '';
    container.appendChild(content);
    document.body.classList.remove('is-loading');
    if (isPopState === false) {
      history.pushState({ url, modal: false }, null, url);
    }
  });
}

function handlePop(event) {
  const modalIsOpen = document.body.classList.contains('modal-is-open');

  if (event.state.modal === false && !modalIsOpen) {
    loadAjaxContent(event.state.url, true);
  }

  if (event.state.modal === false && modalIsOpen) {
    closeModal(true);
  }

  if (event.state.modal === true) {
    openModal(event.state.url, true);
  }
}

export default function() {
  document.addEventListener('click', handleClick);
  window.addEventListener('popstate', handlePop);

  // Initialise history state with current URL
  // and modal value set to false
  const url = location.href;
  history.replaceState({ url, modal: false }, null, url);
}
