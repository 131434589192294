import ajax from './modules/ajax';
import { init as menu } from './modules/menu';
import { init as modal} from './modules/modal';
import slider from './modules/slider';

function init() {
  ajax();
  menu();
  modal();
  slider();
}

document.addEventListener("DOMContentLoaded", init);
